import type { HomeData } from '~/interfaces/Home';

export const useHomeStore = defineStore(
  'home',
  () => {
    const home = ref<HomeData>({
      message: '',
      banner: [],
      all_products: [],
      best_sellers: [],
      recent: [],
      recommended: [],
      blogs: [],
    });

    function setHomeData(data: HomeData) {
      home.value = data;
    }

    return {
      home,
      setHomeData,
    };
  },
  {
    persist: true,
  },
);
