<template>
  <div class="grid grid-cols-12 gap-4">
    <div v-for="story in stories.slice(0, 2)" class="story-card col-span-12 even:lg:col-span-6 xl:col-span-6">
      <NuxtLink :to="generateLink(`/story/${story.slug}`)">
        <NuxtImg :src="story.image" :alt="story.title" class="w-full h-40 object-cover rounded-lg lg:h-[450px]" />
        <div class="absolute bottom-4 z-10 ltr:left-4 rtl:right-4">
          <h4 class="text-white z-10">{{ story.subtitle }}</h4>
          <h3 class="text-white z-10 text-2xl font-bold">{{ story.title }}</h3>
        </div>
      </NuxtLink>
    </div>

    <div class="story-card col-span-12 lg:col-span-6 xl:col-span-4">
      <NuxtLink :to="generateLink(`/story/${stories[2].slug}`)">
        <NuxtImg :src="stories[2].image" alt="Product Image" class="w-full h-40 object-cover rounded-lg lg:h-[450px]" />
        <div class="absolute bottom-4 z-10 ltr:left-4 rtl:right-4">
          <h4 class="text-white z-10">{{ stories[2].subtitle }}</h4>
          <h3 class="text-white z-10 text-2xl font-bold">{{ stories[2].title }}</h3>
        </div>
      </NuxtLink>
    </div>
    <div
      v-for="story in stories.slice(3, 5)"
      class="hidden story-card col-span-12 lg:col-span-6 xl:block xl:col-span-4"
    >
      <NuxtLink :to="generateLink(`/story/${story.slug}`)">
        <NuxtImg :src="story.image" alt="Product Image" class="w-full h-40 object-cover rounded-lg lg:h-[450px]" />
        <div class="absolute bottom-4 z-10 ltr:left-4 rtl:right-4">
          <h4 class="text-white z-10">{{ story.subtitle }}</h4>
          <h3 class="text-white z-10 text-2xl font-bold">{{ story.title }}</h3>
        </div>
      </NuxtLink>
    </div>

    <CommonButton
      class="col-span-full w-full justify-center mx-auto max-w-fit"
      variant="outline"
      color="primary"
      :to="generateLink('/stories/')"
    >
      {{ t('common.view_more') }}
    </CommonButton>
  </div>
</template>

<script lang="ts" setup>
import type { Story } from '~/interfaces/Story';

const { t } = useI18n();
defineProps({
  stories: {
    type: Array as PropType<Story[]>,
    required: true,
  },
});
</script>

<style scoped>
.story-card {
  @apply relative rounded-lg cursor-pointer transition-all hover:shadow hover:scale-[1.02];
}

.story-card::after {
  content: '';
  position: absolute;
  pointer-events: none;
  @apply absolute rounded-md bg-gradient-to-t from-primary-1000/80 to-transparent h-1/2 bottom-0 w-full;
}
</style>
