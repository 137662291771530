<template>
  <div class="flex flex-col md:w-full md:space-y-2 md:mb-2">
    <NuxtLink :to="generateLink(`/blog/${props.slug}`)" class="group transition-all">
      <div class="flex flex-col space-y-1">
        <div
          class="relative rounded-md after:absolute after:bottom-0 after:w-full after:h-24 after:bg-gradient-to-t after:from-black/50 after:rounded-md overflow-hidden"
        >
          <img
            :src="props.image"
            class="md:h-40 h-40 w-full object-cover rounded-md group-hover:scale-[1.1] transition-all overflow-hidden"
          />
        </div>

        <div class="flex flex-col md:justify-between md:py-1">
          <h4 class="text-primary-1000 text-sm font-medium group-hover:font-bold">
            {{ props.title }}
          </h4>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
    default: 'https://picsum.photos/seed/picsum/200/300?random=1',
  },
  readingTime: {
    type: String,
  },
  slug: {
    type: String,
    required: true,
  },
});
</script>

<style scoped></style>
