<template>
  <div class="relative w-full flex flex-col justify-center rounded-xl overflow-hidden category-card mb-6">
    <NuxtLink class="relative select-none" :to="generateLink('/shop/?category=' + slug)">
      <NuxtImg
        :src="props.image"
        class="w-full h-64 object-cover object-center aspect-square rounded-xl transition hover:scale-[1.05] lg:h-[500px]"
        placeholder
        quality="100"
        format="webp"
      />
      <CommonHeading
        :level="3"
        class="rtl:text-center absolute bottom-2 left-1/2 translate-x-[-50%] w-full text-white z-10 uppercase text-center text-sm md:text-lg"
      >
        {{ props.title }}
      </CommonHeading>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
    default: 'Skin & Beauty',
  },
  image: {
    type: String,
    required: true,
    default: 'https://picsum.photos/picsum/200/300',
  },
  slug: {
    type: String,
    required: true,
    default: '',
  },
});
</script>

<style scoped>
.category-card::after {
  content: '';
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;

  height: 50%;
  opacity: 0.6;
  pointer-events: none;
  @apply rounded-xl bg-gradient-to-t from-primary-1000 to-transparent group-hover:after:bg-transparent;
}
</style>
