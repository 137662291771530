<template>
  <div
    class="grid grid-cols-1 items-center mx-auto gap-x-12 pt-12 pb-6 px-6 lg:py-12 lg:grid-cols-5 lg:max-w-5xl xl:max-w-7xl"
  >
    <div class="col-span-1 flex flex-col text-center lg:col-span-2 lg:text-left rtl:lg:text-right">
      <h3 class="flex self-center text-xs my-2 lg:self-start">{{ t('pages.diamonds.subtitle') }}</h3>
      <h2
        class="text-clip text-4xl font-bold bg-gradient-to-r from-neutral-700 via-gray-400 to-blue-100 md:text-5xl ltr:lg:text-left rtl:lg:text-right rtl:leading-relaxed"
      >
        {{ t('pages.diamonds.title') }}
      </h2>
      <p class="mt-2">
        {{ t('pages.diamonds.description') }}
      </p>
      <div class="flex justify-center items-center gap-3 mt-4 lg:justify-start">
        <NuxtLink
          :to="generateLink('/biogena-diamonds')"
          @click="handleViewTracking"
          class="w-52 md:w-64 p-2.5 px-4 text-center rounded-md border bg-neutral-700 text-white uppercase font-medium hover:bg-neutral-500"
        >
          {{ t('common.know_more') }}
        </NuxtLink>
        <button
          class="p-2 px-4 text-center rounded-md border text-neutral-700 uppercase font-medium hover:bg-neutral-400 hover:text-neutral-700"
          @click="handleClick"
        >
          <Icon name="tabler:shopping-bag-plus" class="w-6 h-6" />
        </button>
      </div>
    </div>
    <div class="relative grid grid-cols-2 items-center mx-auto px-4 lg:col-span-3 lg:ml-auto">
      <div class="lg:mb-24 xl:mb-16">
        <div class="relative text-xs my-4 lg:left-24 xl:left-40 ltr:left-4 rtl:right-4">
          <p class="uppercase text-neutral-500">
            {{ t('pages.diamonds.characteristics.keep_your') }}
            <span class="text-black font-medium">{{ t('pages.diamonds.characteristics.energy') }}</span>
          </p>

          <div
            class="relative h-[1px] w-64 bg-black after:absolute after:rounded-full after:w-2 after:h-2 after:bg-neutral-500 after:right-0 after:-top-1 xl:w-72"
          ></div>
        </div>
        <div class="relative text-xs my-4 lg:left-24 xl:left-40 ltr:left-4 rtl:right-4">
          <p class="uppercase text-neutral-500">
            {{ t('pages.diamonds.characteristics.keep_your') }}
            <span class="text-black font-medium">{{ t('pages.diamonds.characteristics.balance') }}</span>
          </p>

          <div
            class="relative h-[1px] w-64 bg-black after:absolute after:rounded-full after:w-2 after:h-2 after:bg-neutral-500 after:right-0 after:-top-1"
          ></div>
        </div>
        <div class="relative text-xs my-4 lg:left-24 xl:left-40 ltr:left-4 rtl:right-4">
          <p class="uppercase text-neutral-500">
            {{ t('pages.diamonds.characteristics.keep_your') }}
            <span class="text-black font-medium">{{ t('pages.diamonds.characteristics.health') }}</span>
          </p>

          <div
            class="relative h-[1px] w-64 bg-black after:absolute after:rounded-full after:w-2 after:h-2 after:bg-neutral-500 after:right-0 after:-top-1"
          ></div>
        </div>
        <div class="relative text-xs my-4 lg:left-24 xl:left-40 ltr:left-4 rtl:right-4">
          <p class="uppercase text-neutral-500">
            {{ t('pages.diamonds.characteristics.keep_your') }}
            <span class="text-black font-medium">{{ t('pages.diamonds.characteristics.focus') }}</span>
          </p>

          <div
            class="relative h-[1px] w-64 bg-black after:absolute after:rounded-full after:w-2 after:h-2 after:bg-neutral-500 after:right-0 after:-top-1"
          ></div>
        </div>
        <div class="relative text-xs my-4 lg:left-24 xl:left-40 ltr:left-4 rtl:right-4">
          <p class="uppercase text-neutral-500">
            {{ t('pages.diamonds.characteristics.keep_your') }}
            <span class="text-black font-medium">{{ t('pages.diamonds.characteristics.glow') }}</span>
          </p>

          <div
            class="relative h-[1px] w-64 bg-black after:absolute after:rounded-full after:w-2 after:h-2 after:bg-neutral-500 after:right-0 after:-top-1"
          ></div>
        </div>
      </div>

      <img src="/biogena-diamonds.png" class="w-full max-w-xs z-10" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();
const gtm = useGtm();
const config = useRuntimeConfig();

const cartStore = useCartStore();
const homeStore = useHomeStore();
const { addToCart } = useKlaviyo();

const { items } = storeToRefs(cartStore);
const { home } = storeToRefs(homeStore);

const diamonds = computed(() =>
  home.value.categories
    .map((category) => category.product)
    .flat()
    .find((product) => product.id === 12),
);

const handleClick = async () => {
  gtm?.trackEvent({
    event: 'add_to_cart',
    is_sticky: false,
    added_from: 'Home-Diamonds Banner',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(diamonds.value?.price),
      items: [
        {
          item_id: diamonds.value?.id,
          item_sku: diamonds.value?.sku,
          item_name: diamonds.value?.name,
          item_category: diamonds.value?.categories?.[0]?.name,
          item_category2: diamonds.value?.categories?.[1]?.name,
          item_category3: diamonds.value?.categories?.[2]?.name,
          price: formatPrice(diamonds.value?.price),
          discount: formatPrice(diamonds.value?.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  if (diamonds.value) {
    await cartStore.addToCart(diamonds.value, 1, locale.value);
    addToCart({
      LanguageCode: locale.value,
      $value: diamonds?.value.price,
      AddedItemProductName: diamonds?.value.name,
      AddedItemProductID: diamonds?.value.id,
      AddedItemSKU: diamonds?.value.sku,
      AddedItemCategories: diamonds?.value.categories?.map((category) => category.name).join(', '),
      AddedItemImageURL: diamonds?.value.thumbnail,
      AddedItemURL: generateLink(`/product/${diamonds?.value.slug}`),
      AddedItemPrice: diamonds?.value.sale_price || diamonds?.value.price,
      AddedItemPriceCurrency: 'AED',
      AddedItemQuantity: 1,
      ItemNames: items.value.map((item) => item.name),
      CheckoutURL: config.public.APP_URL + '/checkout',
      Items: items.value.map((item) => ({
        ProductID: item.id,
        SKU: item.sku,
        ProductName: item.name,
        Quantity: item.quantity,
        ItemPrice: item.price,
        PriceCurrency: 'AED',
        RowTotal: item.price * item.quantity,
        ProductURL: generateLink(`/product/${item.slug}`),
        ImageURL: item.thumbnail,
        ProductCategories: item.categories?.map((category) => category.name),
      })),
    });
  }
};

const handleViewTracking = () => {
  gtm?.trackEvent({
    event: 'view_item',
    navigated_from: 'Home-Diamonds Banner',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(diamonds.value?.price),
      items: [
        {
          item_id: diamonds.value?.id,
          item_sku: diamonds.value?.sku,
          item_name: diamonds.value?.meta_title['en'],
          item_category: diamonds.value?.categories?.[0]?.name,
          item_category2: diamonds.value?.categories?.[1]?.name,
          item_category3: diamonds.value?.categories?.[2]?.name,
          price: formatPrice(diamonds.value?.price),
          discount: formatPrice(diamonds.value?.sale_price),
          quantity: 1,
        },
      ],
    },
  });
};
</script>

<style scoped>
.text-clip {
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
</style>
