<template>
  <div class="grid grid-cols-3 gap-6 mx-auto justify-center items-center mt-6 lg:my-12 lg:flex lg:flex-wrap lg:gap-12">
    <div v-for="principle in principles" class="shrink-0 flex flex-col justify-center text-center items-center gap-4">
      <Icon :name="principle.icon" class="text-primary-1000 w-8 h-8 lg:w-[54px] lg:h-[54px]" />
      <span class="font-medium text-sm lg:text-base" :class="isPrimaryColor ? 'text-primary-1000' : ''">{{
        principle.name
      }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const props = defineProps({
  showPrinciple: {
    type: Boolean,
    default: true,
  },
  isPrimaryColor: {
    type: Boolean,
    default: true,
  },
});

const principles = [
  {
    icon: 'IconVegan',
    name: t('common.principles.vegan'),
  },
  {
    icon: 'IconEcoBottle',
    name: t('common.principles.eco_friendly'),
  },
  {
    icon: 'IconFreeOfAdditives',
    name: t('common.principles.additives'),
  },
  {
    icon: 'IconQualityTested',
    name: t('common.principles.quality'),
  },
  {
    icon: 'IconOnlyActiveIngredients',
    name: t('common.principles.active_ingredients'),
  },
  {
    icon: 'IconHypoallergenic',
    name: t('common.principles.hypoallergenic'),
  },
  {
    icon: 'IconLactoseFree',
    name: t('common.principles.lactose_free'),
  },
  {
    icon: 'IconFructoseFree',
    name: t('common.principles.fructose_free'),
  },
  {
    icon: 'IconGlutenFree',
    name: t('common.principles.gluten_free'),
  },
];
</script>
