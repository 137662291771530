<template>
  <div class="relative mt-4 lg:block">
    <div class="grid grid-cols-1 gap-x-4 my-6 md:grid-cols-2">
      <div class="order-2 self-start md:order-1">
        <h2 class="text-xl font-bold my-2 md:my-4 md:text-4xl ltr:text-left rtl:text-right">
          {{ home.blogs[0].title }}
        </h2>
        <p class="my-2 md:my-4">{{ home.blogs[0].meta_description[splitLocale] }}</p>
        <NuxtLink :to="`${generateLink(`/blog/${home.blogs[0].slug}`)}`">
          <CommonButton class="mt-4" :variant="'outline'">{{ t('common.read_more') }}</CommonButton>
        </NuxtLink>
      </div>
      <div class="order-1 md:order-2">
        <img :src="home.blogs[0].image" alt="Blog Image" class="w-full h-54 object-cover rounded-lg md:h-96" />
      </div>
    </div>

    <div class="relative bg-primary-100 rounded-lg px-6 py-6 lg:px-0">
      <h3 class="text-primary-1000 text-2xl font-bold text-left md:px-12 pb-4">{{ t('pages.home.more_articles') }}</h3>

      <Swiper
        class="blog-slider"
        :modules="[SwiperNavigation]"
        :navigation="true"
        :slides-per-view="1.55"
        space-between="20"
        :breakpoints="{
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
          1280: { slidesPerView: 3 },
        }"
      >
        <SwiperSlide v-for="article in home.blogs.slice(1)" :key="article.id">
          <SharedArticleCard :title="article.title" :image="article.image" :slug="article.slug" />
        </SwiperSlide>
      </Swiper>

      <div class="mt-4">
        <NuxtLink :to="generateLink('blog')">
          <CommonButton class="mx-auto" :variant="'outline'">{{ t('common.view_more') }}</CommonButton>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();
const homeStore = useHomeStore();

const { home } = storeToRefs(homeStore);

const splitLocale = locale.value.split('-')[0];
</script>

<style>
@media (min-width: 1024px) {
  .blog-slider {
    position: unset;
    margin-left: 50px;
    margin-right: 50px;
  }
}
</style>
