<template>
  <div>
    <section id="hero-section">
      <HeroNewHero :banners="home.banner" />
    </section>

    <section id="products-section">
      <CommonHeading :level="2" class="text-2xl text-primary-1000 text-center">{{
        t('pages.home.products')
      }}</CommonHeading>
      <ProductsSlider
        @loadData="handleLoadData"
        :categories="home.categories"
        :products="productsData"
        class="px-4 xl:px-24"
      />
    </section>

    <section id="category-section" class="bg-primary-100 mt-8 py-1 px-6 lg:px-12 lg:pt-4 lg:pb-10">
      <CommonHeading :level="2" class="text-2xl text-primary-1000 text-center my-6">
        {{ t('pages.home.category') }}
      </CommonHeading>
      <CategoryCarousel />
    </section>

    <section id="principle-section" class="container my-6 p-6 lg:p-12">
      <CommonHeading :level="2" class="text-2xl text-primary-1000 text-center">
        {{ t('pages.home.principles') }}
      </CommonHeading>

      <SharedPrinciples />
    </section>

    <section id="diamonds-section" class="bg-gradient-to-r from-slate-50">
      <BiogenaDiamondsDiamondSection />
    </section>

    <section id="story-section" class="bg-primary-100 p-6 lg:pb-16">
      <CommonHeading :level="2" class="text-primary-1000 text-center my-6"> {{ t('pages.home.story') }} </CommonHeading>
      <StoryGrid class="container" :stories="stories" />
    </section>

    <section id="blog-section" class="container p-6 lg:pt-12 lg:px-12">
      <CommonHeading :level="2" class="text-2xl text-primary-1000 text-center">
        {{ t('pages.home.latest_blogs') }}
      </CommonHeading>
      <BlogSection class="container" />
    </section>
  </div>
</template>

<script setup lang="ts">
import type { HomeCategory, HomeFetchResponse } from '~/interfaces/Home';
import type { Product } from '~/interfaces/Product';

const { t } = useI18n();

useSeoMeta({
  description: t('seo.home_description'),
  ogTitle: t('seo.home_title'),
  ogDescription: t('seo.home_description'),
  ogImage: 'https://biogena-me.sgp1.cdn.digitaloceanspaces.com/seo_image.png',
  ogImageUrl: 'https://biogena-me.sgp1.cdn.digitaloceanspaces.com/seo_image.png',
  ogUrl: 'https://biogena-me.com/en/',
  twitterTitle: t('seo.home_title'),
  twitterDescription: t('seo.home_description'),
  twitterImage: 'https://biogena-me.sgp1.cdn.digitaloceanspaces.com/seo_image.png',
});

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Biogena ME',
  image: 'https://biogena-me.sgp1.cdn.digitaloceanspaces.com/seo_image.png',
  url: 'https://biogena-me.com/en/',
});

const nuxtApp = useNuxtApp();
const { locale } = useI18n();

// const experimentStore = useExperimentStore();
const homeStore = useHomeStore();
const storyStore = useStoryStore();

// const { variant } = storeToRefs(experimentStore);
const { home } = storeToRefs(homeStore);

const { setHomeData } = homeStore;
const { setStories } = storyStore;

const { stories } = storeToRefs(storyStore);

const { error } = await useFetch<HomeFetchResponse>('/api/home', {
  key: 'home',
  onResponse({ response }) {
    setHomeData(response._data.data);
    setStories(response._data.data.stories);
  },
  headers: {
    'Accept-Language': locale.value.split('-')[0].split('-')[0],
  },
  transform(input) {
    return {
      ...input,
      fetchedAt: new Date(),
      language: locale.value,
    };
  },
  getCachedData(key) {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

    if (!data) {
      return;
    }

    const expirationDate = new Date(data.fetchedAt);
    expirationDate.setTime(expirationDate.getTime() + 3600 * 1000);
    const isExpired = expirationDate.getTime() < Date.now();

    if (isExpired) {
      return;
    }

    if (locale.value !== data.language) {
      return;
    }

    return data;
  },
});

if (error.value) {
  throw createError({
    fatal: true,
    statusCode: 400,
    message: 'Something went wrong',
  });
}

const productsData = ref<Product[]>(home.value.all_products || []);

const handleLoadData = (category: HomeCategory) => {
  if (category.name === 'all') {
    return (productsData.value = home.value.all_products);
  } else {
    return (productsData.value = category.product);
  }
};

// onBeforeMount(() => {
//   experimentStore.randomizeVariant();
// });
</script>
