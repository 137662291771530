<template>
  <div class="relative">
    <Swiper
      ref="categorySwiper"
      class="mb-6 category-carousel"
      :modules="[SwiperNavigation, SwiperAutoplay]"
      :slides-per-view="1.55"
      :slides-per-group="1"
      :loop="true"
      :speed="5000"
      :autoplay="props.hasAutoplay ? { delay: 0, reverseDirection: props.reverseDirection } : false"
      :navigation="{ enabled: true }"
      :breakpoints="{
        576: { slidesPerView: 3.15, slidesPerGroup: 3 },
        768: { slidesPerView: 3.15, slidesPerGroup: 3 },
        1024: { slidesPerView: 3.15, slidesPerGroup: 3 },
        1280: { slidesPerView: 5, slidesPerGroup: 5, autoplay: false },
      }"
    >
      <SwiperSlide v-for="(category, index) in categoriesData" :key="category.id" class="mx-2">
        <SharedCategoryCard
          :key="category.id"
          :title="category.name"
          :image="category.image"
          :slug="category.slug"
          class="max-w-[330px]"
        />
      </SwiperSlide>
    </Swiper>

    <CommonButton
      v-if="props.showViewMore"
      class="w-full justify-center mx-auto max-w-fit"
      variant="outline"
      color="primary"
      :to="generateLink('/shop/')"
    >
      {{ t('common.view_more') }}
    </CommonButton>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const categoryStore = useCategoryStore();

const { categories } = storeToRefs(categoryStore);

const categoriesData = computed(() => {
  return categories.value.map((category) => ({
    ...category,
    id: category.id,
    name: category.name,
    image: category.image,
  }));
});

const props = defineProps({
  hasAutoplay: {
    type: Boolean,
    default: false,
  },
  reverseDirection: {
    type: Boolean,
    default: false,
  },
  showViewMore: {
    type: Boolean,
    default: true,
  },
});
</script>

<style>
@media (min-width: 1024px) {
  .category-carousel {
    position: unset;
    margin-left: 50px;
    margin-right: 50px;
  }

  .category-carousel .swiper-slide {
    max-width: fit-content;
  }
}

.category-carousel .swiper-slide {
  height: auto;
  width: auto;
  display: flex;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
}
</style>
