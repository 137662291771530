<template>
  <div class="mt-[125px] lg:mt-[196px] mx-4">
    <Swiper
      ref="newHeroSwiper"
      class="hero-swiper"
      :modules="[SwiperPagination, SwiperAutoplay, SwiperNavigation]"
      :slides-per-view="1.1"
      :navigation="banners.length > 2 ? true : false"
      :breakpoints="{
        768: { slidesPerView: banners.length > 2 ? 2.15 : 2, slidesPerGroup: 1 },
        1024: { slidesPerView: banners.length > 2 ? 2.15 : 2, slidesPerGroup: 1 },
        1280: { slidesPerView: banners.length > 2 ? 2.15 : 2, slidesPerGroup: 1 },
      }"
    >
      <SwiperSlide
        class="hero-slide max-h-[400px] lg:max-h-[500px] lg:h-[500px] pb-12 mx-2"
        v-for="(hero, index) in banners"
        :key="hero.image[locale]"
      >
        <div class="cursor-pointer" @click="handleBannerClick(index, hero.link)">
          <NuxtImg
            :src="hero.image[splitLocale]"
            format="webp"
            class="w-full object-cover h-56 object-[55%] lg:h-[300px] rounded-lg"
            alt="Home Banner Image"
          />
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import type { Banner } from '~/interfaces/Home';

defineProps({
  banners: {
    type: Array as PropType<Banner[]>,
    required: true,
  },
});

const gtm = useGtm();
const { locale } = useI18n();

const handleBannerClick = (index: number, link: string) => {
  gtm?.trackEvent({
    event: 'click',
    category: 'Banner',
    label: 'Banner Click',
    value: index,
    link: link,
  });

  window.location.href = link;
};

const splitLocale = locale.value.split('-')[0];
</script>

<style>
.hero-swiper > .swiper-pagination {
  bottom: 24px;
  right: 4px;
  width: auto !important;
}

.hero-swiper .swiper-pagination-bullet {
  @apply bg-primary-200;
  width: 20px;
  opacity: 1;
  height: 6px;
  border-radius: 2px;
  transition: width 200ms ease;
}
.hero-swiper .swiper-pagination-bullet-active {
  @apply bg-primary-1000;
  width: 40px;
}

.hero-swiper .swiper-slide {
  max-width: fit-content;
}
</style>
